<template>
  <v-row
    v-if="isAuth"
    justify="center" 
  >
    <v-dialog
      v-model="dialog"
      persistent
      scrollable
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="#FFC300"
          style="margin: 20px 0;"
          @click="handleOpenWorkspaceSetting"
        >
          {{ $t('dtouch.workspaceSetting.title', locale) }}
        </v-btn>
      </template>
      <v-card v-if="showForm">
        <v-card-text style="height: 80%;">
          <workspace-form
            :isClone="false"
            :editedID="workspaceID"
            :showForm="showForm"
            :onDelete="handleDelete"
            :onSave="handleSave"
            :onClone="handleSaveClone"
            :onClose="handleCloseForm"
            :isFloatingSetting="true"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import utils from '@/services/utils'
import api from '@/services/api'
import WorkspaceForm from '../../../../../sys/dtouch/workspaceForm/Index'
export default {
  name: 'WorkspaceSetting',
  components: {
    WorkspaceForm,
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isAuth: false,
    dialog: false,
    showForm: false,
    workspaceID: null,
  }),
  mounted () {
    this.checkAuth()
  },
  methods: {
    handleOpenWorkspaceSetting () {
      this.workspaceID = utils.getWorkspace('dtouch')
      this.showForm = true
    },
    checkAuth () {
      api.getItem ('system', `v1/internal/environments/`, utils.getUser())
        .then(response => {
          this.isAuth = response.Environments.indexOf('sys') >= 0
        })
    },
    handleDelete () {
      alert('no permitido') 
      this.handleCloseForm()
    },
    handleSave (v) {
      api.updateItem ('dtouch', 'v1/system/workspaces/', v.ID, v)
        .then(() => { 
          this.handleCloseForm()
        })  
    },
    handleSaveClone () {
      
    },
    handleCloseForm () {
      this.workspaceID = null
      this.showForm = false
      this.dialog = false
    },
  },
}
</script>

